import { useEffect } from 'react';

const useFetchCampaignData = (cid, setCampaignData) => {
  useEffect(() => {
    const fetchCampaignData = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_BASE_URL}/${process.env.REACT_APP_API_VERSION}/campaigns/${cid}`);
        const contentType = response.headers.get("content-type");
        
        if (contentType && contentType.includes("application/json")) {
          const data = await response.json();
          console.log("Fetched campaign data:", data);
          setCampaignData(data);
        } else {
          console.error("Unexpected content type:", contentType);
          const text = await response.text();
          console.error("Response text:", text);
        }
      } catch (error) {
        console.error("Error fetching campaign data:", error);
      }
    };

    fetchCampaignData();
  }, [cid, setCampaignData]);
};

export default useFetchCampaignData;