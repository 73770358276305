

export const OverlayContentLoading = () => {
  return (
  <>
    <div className="columns is-centered">
      <div className="column is-full m-6">
          <div className='block'>
              <p>Loading...</p>
          </div>
          <div className='block'>
            <progress className="progress is-small is-primary" max="100"></progress>
          </div>
      </div>
    </div>
</>
  );
};

export const OverlayContentLoadingCampaigns = () => {
  return (
  <>
    <div className="columns is-centered">
      <div className="column is-full m-6">
          <div className='block'>
              <p>Načítám kampaně...</p>
          </div>
          <div className='block'>
            <progress className="progress is-small is-primary" max="100"></progress>
          </div>
      </div>
    </div>
</>
  );
};

export const OverlayContentErrorMessage = ({ errorMessage }) => {
  return (
  <>
    <div className="columns is-centered">
      <div className="column is-full m-6">
          <div className='block'>
              <p className="has-text-centered has-text-danger">{ errorMessage }</p>
          </div>

      </div>
    </div>
</>
  );
};

export const PaymentConfirmationContent = ({ onButtonClick }) => {
  return (
<section div className="container has-text-centered">
      <div className="columns is-centered">
        <div className="column ">
          <div className="block">
            <h1 className="title has-text-black">
              Děkujeme za potvrzení o zaplacení!
            </h1>
          </div>
          <div className="block">
            <p className="subtitle has-text-black">
              Co nejdříve se podíváme na účet a v případě uhrazení Vám zašleme vstupenky na Váš email.
            </p>
          </div>
          <div className="block">
            <div className="content">
              <p>Přejeme vám hezký den!</p>
            </div>
          </div>
          <div className="block">
            <button onClick={onButtonClick} className="button is-primary">
              Přejít na domovskou stránku
            </button>
          </div>
        </div>
      </div>
</section>

  );
};


export const PaymentDetailsContent = ({ onButtonClick }) => {
  return (
<section div className="container has-text-centered">
      <div className="columns is-centered">
        <div className="column is-three-quarters">
          <div className="block">
            <h1 className="title has-text-black mb-6">
            Děkujeme za objednávku!
            </h1>
          </div>
          <div className="block">
            <p className="subtitle has-text-black">
            Podklady pro jednoduchou platbu ve Vašem internetovém bankovnictví byly zaslány na Váš email.
            </p>
          </div>
          <div className="block">
            <div className="content">
              <p>Přejeme vám hezký den!</p>
            </div>
          </div>
          <div className="block">
            <button onClick={onButtonClick} className="button is-primary">
              Přejít na domovskou stránku
            </button>
          </div>
        </div>
      </div>
</section>

  );
};

export const GDPRContent = ({ onButtonClick }) => {
  return (
<section div className="container has-text-centered">
      <div className="columns is-centered">
        <div className="column is-three-quarters">
          <div className="block">
            <h1 className="title has-text-black mb-6">
            Ochrana osobních údajů
            </h1>
          </div>
          <div className="block">
            <p className="subtitle has-text-black">
            Vaše data shromažďujeme pouze v rozsahu nutném pro účel plnění smlouvy resp. realizace Vašeho nákupu vstupenek.
            </p>
          </div>

          <div className="block">
            <p className="subtitle has-text-black">
            Z Vašich dat sbíráme a skladujeme pouze Váš email, co jste si objednali a kdy.
            </p>
          </div>
          <div className="block">
            <p className="subtitle has-text-black">
            Vaše data neprodáváme, k dispozici je má organizátor příslušné akce, aby s Vámi mohl komunikovat v případě nutnosti.
            </p>
          </div>

          <div className="block">
            <div className="content">
              <p>Přejeme vám hezký den!</p>
            </div>
          </div>
        </div>
      </div>
</section>

  );
};
