import React, { useState, useEffect } from 'react';

const EmailInput = ({ label, placeholder, invalidMessage, onEmailChange, showInvalidMessage }) => {
  const [email, setEmail] = useState('');
  const [isValid, setIsValid] = useState(true);

  const validateEmail = (email) => {
    const regex = /^(?=.{1,64}@.{1,255}$)(?=.{1,64})([A-Za-z0-9!#$%&'*+/=?^_`{|}~-]+(\.[A-Za-z0-9!#$%&'*+/=?^_`{|}~-]+)*|".+")@[A-Za-z0-9-]+(\.[A-Za-z0-9-]+)*(\.[A-Za-z]{2,})$/;
    return regex.test(email);
  };

  const handleChange = (event) => {
    const value = event.target.value;
    setEmail(value);
    const isValid = validateEmail(value);
    setIsValid(isValid);
    onEmailChange(value, isValid);
  };

  // Effect to handle showing invalid message based on parent component state
  useEffect(() => {
    if (email) {
      setIsValid(validateEmail(email));
    }
  }, [email]);

  return (
    <div className="container">
      <div className="field">
        <label className="label">{label}</label>
        <div className="control">
          <input
            className={`input ${isValid || !showInvalidMessage ? 'is-primary' : 'is-danger'}`}
            type="email"
            placeholder={placeholder}
            value={email}
            onChange={handleChange}
          />
        </div>
        {(showInvalidMessage || !isValid) && (
          <p className="help is-danger">{invalidMessage}</p>
        )}
      </div>
    </div>
  );
};

export default EmailInput;
