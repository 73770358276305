import React from 'react';
import { useLocation, useNavigate, Link } from 'react-router-dom';
import { CenteredLogo } from '../components/Logo';
import './PaymentQRPage.css';
import Navbar from '../components/Navbar';

const PaymentQRPage = () => {
  const location = useLocation();
  const navigate = useNavigate(); // Add this line
  const orderData = location.state?.orderData;

  if (!orderData) {
    return <div>Error: Order data not found</div>;
  }

  const handlePaymentConfirm = async () => {
      navigate(`/payment_confirm/${orderData.id}`);
  };
  

  const handleDownload = async () => {
    const orderId = orderData.id;
    const downloadUrl = `${process.env.REACT_APP_BASE_URL}/${process.env.REACT_APP_API_VERSION}/download_payment_info/${orderId}`;
    
    try {
      const response = await fetch(downloadUrl);
      console.log('response handleDownload');
      console.log(response);
      if (!response.ok) {
        const errorData = await response.text();
        alert(`Chyba při stahování PDF: ${errorData}`);
        console.error('Error downloading payment info:', errorData);
        return;
      }

      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);

      const link = document.createElement('a');
      link.href = url;
      link.download = `Vstupenky - podklady pro platbu.pdf`;  // XXX hard-coded
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      alert(`Chyba: ${error.message}`);
      console.error('Error:', error);    }
  };

  const handleSendEmail = async () => {
    const oid = orderData.id;
    const response = await fetch(`${process.env.REACT_APP_BASE_URL}/${process.env.REACT_APP_API_VERSION}/payment_details_email_request?oid=${oid}`);

    if (response.ok) {
      navigate('/payment_details_email_request', { state: { orderData } });
    } else {
      console.error("Email request failed");
    }
  };


  return (
  <> 
    
      <Navbar />
    
      <div className='container-content'>
        <div className="columns is-centered has-text-centered">
          <div className='column is-8 is-full-mobile'>
          <div className='box custom-box mt-5 p-3 p-desktop-6'>
            <h1 className="title has-text-primary has-text-centered m-6">Objednávka vstupenek</h1>
            <div className="container">


            <div className="block">

              <div className='columns is-centered'>
                <div className='column is-half-fullhd is-three-quarters-desktop is-full-tablet is-full-mobile'>

                    <table style={{ borderCollapse: 'collapse' }}>
                        <tbody>
                            <tr>
                                <td>Objednávka ID:</td>
                                <td>{orderData.id}</td>
                            </tr>
                            <tr>
                                <td>Počet vstupenek:</td>
                                <td>{orderData.ordered_item_count}</td>
                            </tr>
                            <tr>
                                <td>Emailová adresa:</td>
                                <td>{orderData.email_address}</td>
                            </tr>
                            <tr>
                                <td>Celkem:</td>
                                <td className="no-wrap">{(orderData.order_total / 100).toFixed(2)} Kč</td>
                            </tr>
                        </tbody>
                    </table>

                </div>

              </div>

          </div>

          <div className="container-qr">
              <p>Zde je QR kód s připraveným platebním příkazem:</p>
              <div className="qr-code">
                  <img src={`data:image/png;base64,${orderData.qr_payment}`} alt="QR kód pro platbu" />
              </div>     
              <p>Příjemce: Spolek Almara, Liptál 36, 75631 Liptál, IČO 10861921</p>
              <div style={{display: 'inline-block',border: '1px solid black', borderRadius: '5px', margin: '0 auto', padding: '5px 10px'}}>   
              <p>Č. účtu: 2002958537/2010</p>
              <p>VS: {orderData.variable_symbol}</p>
              </div>

          </div>   
  

  <div className='columns is-centered'>
    <div className='column is-half has-text-center'>

      <div className="block">
      <div className="button-container">

        <button className="button is-primary is-medium" onClick={handlePaymentConfirm}>Zaplaceno</button>
        </div>
        </div>

      <div className="block">
        <p>Pokud nemáte možnost oskenovat QR kód nyní, můžete si nechat platební instrukce nechat zaslat na email nebo stáhnout.</p>
      </div>

      <div className="button-container mb-5">
        <button className="button has-background-primary-light" onClick={handleSendEmail}>Poslat na email</button>
        <button className="button has-background-primary-light" onClick={handleDownload}>Stáhnout</button>
      </div>

    </div>
  </div>


            </div>
        


        </div>
        </div>
        </div>
      </div>
</>
  );
};

export default PaymentQRPage;
