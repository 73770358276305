import React from 'react';
import '../pages/HomePage.css';

const ProcessSection = () => {
    return (
        <section className="section" id="section-process">
            <div className="container">
                <h1 className="title has-text-primary has-text-centered">Jak to funguje</h1>
                <div className="process-container">
                    <div className="columns is-centered">
                        <div className="column">
                            <div className="box custom-box">
                                <div className="columns is-centered">
                                    <div className="column is-full-mobile is-three-quarters-tablet">
                                        <table>
                                            <tbody>
                                                <tr>
                                                    <td>
                                                        <span className="icon"><i className="fa-solid fa-plus-circle"></i></span>
                                                    </td>
                                                    <td>
                                                        <p>Jednoduše založíte kampaň pro prodej Vašich vstupenek.</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <span className="icon"><i className="fa-solid fa-link"></i></span>
                                                    </td>
                                                    <td>
                                                        <p>Link umístíte jednoduše na Vaši stránku.</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <span className="icon"><i className="fa-solid fa-globe"></i></span>
                                                    </td>
                                                    <td>
                                                        <p>Veřejná akce se zobrazí v nabídce i na stránkách kuplupeny.cz.</p>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="columns is-centered">
                        <div className="column">
                            <div className="box custom-box">
                                <div className="columns is-centered">
                                    <div className="column is-full-mobile is-three-quarters-tablet">
                                        <table>
                                            <tbody>
                                                <tr>
                                                    <td>
                                                        <span className="icon"><i className="fa-solid fa-ticket"></i></span>
                                                    </td>
                                                    <td>
                                                        <p>Lidé si objednají lístek.</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <span className="icon"><i className="fa-solid fa-qrcode"></i></span>
                                                    </td>
                                                    <td>
                                                        <p>Zaplatí jednoduše pomocí QR kódu s platebními instrukcemi.</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <span className="icon"><i className="fa-solid fa-envelope"></i></span>
                                                    </td>
                                                    <td>
                                                        <p>Po zaplacení jim přijde vstupenka na email.</p>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="columns is-centered">
                        <div className="column">
                            <div className="box custom-box">
                                <div className="columns is-centered">
                                    <div className="column is-full-mobile is-three-quarters-tablet">
                                        <table>
                                            <tbody>
                                                <tr>
                                                    <td>
                                                        <span className="icon"><i className="fa-solid fa-user-check"></i></span>
                                                    </td>
                                                    <td>
                                                        <p>Na akci u vchodu zkontrolujete platnost vstupenky.</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <span className="icon"><i className="fa-solid fa-champagne-glasses"></i></span>
                                                    </td>
                                                    <td>
                                                        <p>Užijete si akci, protože jste nemuseli trávit čas kontrolováním účtu a vyjasňováním způsobu zaplacení.</p>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default ProcessSection;
