import React, { useEffect, useRef } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';

const loadRecaptchaScript = (siteKey) => {
  const scriptId = 'recaptcha-script';
  if (!document.getElementById(scriptId)) {
    const script = document.createElement('script');
    script.id = scriptId;
    script.src = `https://www.google.com/recaptcha/enterprise.js?render=${siteKey}`;
    script.async = true;
    script.defer = true;
    document.body.appendChild(script);
  }
};

const Recaptcha = ({ onChange }) => {
  const recaptchaRef = useRef(null);
  const siteKey = process.env.REACT_APP_RECAPTCHA_SITE_KEY;

  useEffect(() => {
    loadRecaptchaScript(siteKey);
  }, [siteKey]);

  const handleRecaptchaChange = (value) => {
    onChange(value);
  };

  return (
    <ReCAPTCHA
      ref={recaptchaRef}
      sitekey={siteKey}
      onChange={handleRecaptchaChange}
    />
  );
};

export default Recaptcha;
