import { useState, useEffect } from 'react';

// Utility function to create a timeout for the fetch request
// const fetchWithTimeout = (url, options, timeout = 5000) => {
//   return new Promise((resolve, reject) => {
//     const timer = setTimeout(() => {
//       reject(new Error('Request timed out'));
//     }, timeout);

//     fetch(url, options)
//       .then((response) => {
//         clearTimeout(timer);
//         resolve(response);
//       })
//       .catch((error) => {
//         clearTimeout(timer);
//         reject(error);
//       });
//   });
// };

const useFetchCampaigns = () => {
  const [campaignsData, setCampaignsData] = useState([]);
  const [loading, setLoading] = useState(true); // Manage loading state

  useEffect(() => {
    const fetchCampaigns = async () => {
      try {
        // console.log("about to hit loading");

        // Use fetchWithTimeout to handle endless loading
        // const loadingTime = 2000;
        // const response = await fetchWithTimeout(`${process.env.REACT_APP_BASE_URL}/test-loading/${loadingTime}`, {}, loadingTime); // Timeout after 10 seconds
        const response = await fetch(`${process.env.REACT_APP_BASE_URL}/${process.env.REACT_APP_API_VERSION}/campaigns`);

        const contentType = response.headers.get("content-type");

        if (contentType && contentType.includes("application/json")) {
          const data = await response.json();
          console.log("Fetched campaign data:", data);
          setCampaignsData(data);
        } else {
          console.error("Unexpected content type:", contentType);
          const text = await response.text();
          console.error("Response text:", text);
        }
      } catch (error) {
        console.error("Error fetching campaign data:", error);
      } finally {
        setLoading(false); // Ensure the loading state is updated
      }
    };

    fetchCampaigns();
  }, []);

  return { campaignsData, loading }; // Return campaignsData and loading state
};

export default useFetchCampaigns;
