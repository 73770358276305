import { Link } from 'react-router-dom';
import useFetchCampaigns from '../hooks/useFetchCampaigns';
// import useCollectUserInfo from '../hooks/useCollectUserInfo';
import EventCard from '../components/EventCard';
import ProcessSection from '../components/ProcessSection';
import ContactForm from '../components/ContactForm';
import './HomePage.css';
import Separator from '../components/Separator';
import Navbar from '../components/Navbar';
import { Overlay } from '../components/Overlay';
import { OverlayContentLoadingCampaigns } from '../components/OverlayContents';

const HomePage = () => {
    const { campaignsData, loading } = useFetchCampaigns(); // Destructure loading and campaignsData
    // useCollectUserInfo();

    // Function to handle the burger menu toggle

    return (
        <>
            <div style={{ marginBottom:'150px'}}>
            <Navbar/>
            {loading && (
                <Overlay 
                    content={<OverlayContentLoadingCampaigns />} 
                    onClose={() => {}}
                    hideCloseButton={false} // Pass a prop to hide the close button
                />
            )}
            {/* Hero Section */}
            <section className="hero is-primary is-halfheight">
                <div className="hero-body">
                    <div className="container has-text-centered">
                    <div className="columns is-centered">
                    <div className="column is-half">    
                        <h1 className="title is-2-desktop is-5-mobile mb-6">kuplupeny.cz</h1>
                        <h2 className="subtitle is-4">
                            Nízkonákladové řešení pro prodej vstupenek na komunitní setkání a společenské a kulturní akce neziskového sektoru.
                        </h2>
                        {/* <div className="buttons is-centered mt-6">
                            <a className="button is-light is-large" href="#section-process">
                                Chci vědět víc
                            </a>
                        </div> */}
                        </div>
                    </div>
                    </div>
                </div>
            </section>
            {/* Event Cards Section */}
            <section className="section" id="events">
                <div className="container">
                    <h1 className="title has-text-primary has-text-centered">Nadcházející akce</h1>
                    {Array.isArray(campaignsData) && campaignsData.length > 0 ? (
                        campaignsData.map((campaign) => (
                            <Link to={`/campaigns/${campaign.id}`} key={campaign.id}>
                            <EventCard
                                    name={campaign.name}
                                    date={campaign.date}
                                    location={campaign.location}
                                    // description={campaign.description}
                                />
                            </Link>
                        ))
                    ) : (
                        <div className='container'>
                            <div className='columns is-centered'>
                                <div className='column'></div>
                                <div className='column is-three-fifth has-text-left is-size-5'>Žádné plánované veřejné akce</div>
                                <div className='column'></div>
                            </div>
                                <div className='columns is-centered'>
                                <div className='column'></div>
                                <div className='column is-three-fifth has-text-right is-size-5'>... možná jsou nějaké soukromé :-)</div>
                                <div className='column'></div>
                            </div>
                        </div>
                    )}
                </div>
            </section>


            <Separator color="primary" iconSize="2em"/>
            <ProcessSection />
            <Separator color="primary" iconSize="2em"/>
            {/* Advantages Section */}
            <section id="section-advantages">
                <div className="container">
                    <h1 className="title has-text-primary has-text-centered">Má to své výhody...</h1>
                        <div className="columns is-centered">
                            <div className="column is-three-quarters-desktop is-full-mobile">

                                
                                <div className="icontext">
                                        <i className="fa-regular fa-hand-point-right"></i>
                                    <span>peníze přijdou přímo na Váš účet</span>
                                </div>

                                <div className="icontext">
                                        <i className="fa-regular fa-hand-point-right"></i>
                                    <span>máte přehled o prodaných vstupenkách</span>
                                </div>

                                <div className="icontext">
                                        <i className="fa-regular fa-hand-point-right"></i>
                                    <span>méně než poloviční náklady oproti běžným platebním branám</span>
                                </div>
                                <div className="icontext">
                                        <i className="fa-regular fa-hand-point-right"></i>
                                    <span>možnost prodeje vstupenek i na uzavřené akce pomocí soukromého linku (nezobrazí se v nabídce na kuplupeny.cz)</span>
                                </div>
                        </div>
                    </div>
                </div>
            </section>
            
            <Separator color="primary" iconSize="2em"/>

            {/* Contact Section */}
            <section className="section" id="contact">
                <div className="container">
                    <h2 className="title has-text-primary has-text-centered">Kontakt</h2>
                    <div className="columns is-centered">
                        <div className="column is-half">
                            <ContactForm />
                        </div>
                    </div>
                </div>
            </section>
        </div>    

        </>

    );
};

export default HomePage;
