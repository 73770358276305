import { useNavigate } from 'react-router-dom';
import { Overlay } from '../components/Overlay';
import { PaymentDetailsContent } from '../components/OverlayContents';

const PaymentDetailsEmailRequest = () => {
  const navigate = useNavigate();
   
  const handleRedirect = () => {
    navigate('/');
  };

  return (
    <Overlay
      content={<PaymentDetailsContent onButtonClick={handleRedirect} />}
      hideCloseButton={true}
    />
  );
};

export default PaymentDetailsEmailRequest;