import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Overlay } from '../components/Overlay';
import { PaymentConfirmationContent } from '../components/OverlayContents';
import useConfirmPayment from '../hooks/useConfirmPayment';

const PaymentConfirmation = () => {
  const { oid } = useParams(); // Extract 'oid' from URL params
  const navigate = useNavigate();
  const { confirmPayment, loading, error } = useConfirmPayment();

  useEffect(() => {
    if (oid) {
      confirmPayment(oid)
        .then(response => {
          // Optionally handle the response or perform additional actions
        })
        .catch(err => {
          console.error('Error confirming payment:', err);
        });
    }
  // }, [oid, confirmPayment]);
}, []);

  const handleRedirect = () => {
    navigate('/');
  };

  return (
    <Overlay
      content={<PaymentConfirmationContent onButtonClick={handleRedirect} />}
      hideCloseButton={true}
      loading={loading}
      error={error}
    />
  );
};

export default PaymentConfirmation;
