import React from 'react';
import './EventCard.css'; // Import any additional styles if needed

const EventCard = ({ name, date, location, description }) => {
    const formatDate = (isoDateString) => {
        const options = { year: 'numeric', month: 'long', day: 'numeric' };
        const dateObj = new Date(isoDateString);
        return dateObj.toLocaleDateString('cs-CZ', options);
    };
    const tdStyle = {
        border: 'none',
        padding: '0.0rem 1rem',
        textAlign: 'left',
        fontSize: '1.2em',
        whiteSpace: 'no-wrap'
        };

    return (
        <div className="box custom-box mb-4">
            <article className="media">
                <div className="media-content">
                    <div className="content">
                        <h4 className="title is-5">{name}</h4>
                        <div className="block">

                            <div className='columns'>
                                <div className='column is-half'>

                                    <table className="table" style={{ borderCollapse: 'collapse' }}>
                                        <tbody>
                                            <tr>
                                            <td style={tdStyle}>
                                                        <span className="icon"><i className="fa-solid fa-calendar-days"></i></span>
                                                </td>
                                                <td style={tdStyle}><strong>Datum:</strong></td>
                                                <td style={tdStyle}>{formatDate(date)}</td>
                                            </tr>

                                            <tr>
                                                <td style={tdStyle}>
                                                    <span className="icon"><i className="fa-solid fa-map-marker-alt"></i></span>
                                                </td>
                                                <td style={tdStyle}><strong>Místo</strong></td>
                                                <td style={tdStyle}>{location}</td>
                                            </tr>

                                        </tbody>
                                    </table>

                                </div>
                            </div>

                    </div>
                        {/* Description */}
                        <div className="mt-2">
                            {description}
                        </div>
                    </div>
                </div>
            </article>
        </div>
    );
};

export default EventCard;
