import React, { useState } from 'react';
import './Overlay.css'; // You'll style the modal here

export const Overlay = ({ content, onClose, hideCloseButton = false }) => {
  return (
    <div className="preview-overlay">
      <div className="preview-modal">
        <div className='container'>
          <div className='columns is-centered'>
            < div className='column is-full'>
            
            {!hideCloseButton && (
            <div className='block  has-text-right'>
                  <button
                    className="close-button"
                    onClick={onClose}
                    aria-label="Close"
                  >
                    <i className="fa-solid fa-circle-xmark"></i>
                  </button>
            </div>
            )}            
            <div className='block'>
                <div className="preview-content">
                  {content}
                </div>
            </div>            
            </div>

          </div>
        </div>
          


      </div>
    </div>
  );
};
