import React from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import HomePage from './pages/HomePage';
import OrderPage from './pages/OrderPage';
import PaymentQRPage from './pages/PaymentQRPage';
import PaymentConfirmation from './pages/PaymentConfirmation';
import PaymentDetailsEmailRequest from './pages/PaymentDetailsEmailRequest';

const AppLayout = () => {
  const location = useLocation();
  const isConfirmationOverlay = location.pathname.startsWith('/payment_confirm');
  const isEmailRequestOverlay = location.pathname === '/payment_details_email_request';

  return (
    <div>
        <HomePage />
        {isConfirmationOverlay && <PaymentConfirmation />}
        {isEmailRequestOverlay && <PaymentDetailsEmailRequest />}
    </div>
  );
};

const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<AppLayout />} />
        {/* <Route path="/payment_confirm/:oid" element={<AppLayout />} /> */}
        <Route path={`/payment_confirm/:oid`} element={<AppLayout />} />
        <Route path="/payment_details_email_request" element={<AppLayout />} />
        <Route path={`/campaigns/:id`} element={<OrderPage />} />
        <Route path="/payment-qr-page" element={<PaymentQRPage />} />
      </Routes>
    </Router>
  );
};

export default App;
