import { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import useFetchCampaignData from '../hooks/useFetchCampaignData';
import useFetchOrder from '../hooks/useFetchOrder';
// import useCollectUserInfo from '../hooks/useCollectUserInfo';
import EmailInput from '../components/EmailInput';
import './HomePage.css';
import { OverlayContentErrorMessage } from '../components/OverlayContents';
import { Overlay } from '../components/Overlay';
import Navbar from '../components/Navbar';

const ProductList = ({ products: initialProducts, onProductsChange }) => {
  const [products, setProducts] = useState(initialProducts);

  useEffect(() => {
    setProducts(initialProducts);
  }, [initialProducts]);

  useEffect(() => {
    onProductsChange(products);
  }, [products, onProductsChange]);

  const handleQuantityChange = (id, quantity) => {
    setProducts(products.map(product => 
      product.id === id ? { ...product, quantity: parseInt(quantity, 10) } : product
    ));
  };

  const calculateTotal = () => {
    return products.reduce((total, product) => total + (product.unit_price / 100) * product.quantity, 0);
  };

  return (
<>
    <div className="container">
        <table className="table is-fullwidth is-striped is-hoverable">
          <thead>
            <tr>
              <th>Položka</th>
              <th className="has-text-right">Cena (Kč/ks)</th>
              <th>Počet (ks)</th>
              <th className="has-text-right">Cena (Kč)</th>
            </tr>
          </thead>
          <tbody>
              {products.map(product => (
                  <tr key={product.id} className=''>
                    <td className="has-text-left is-vcentered">{product.name}</td>
                    <td className="has-text-right is-vcentered no-wrap">{(product.unit_price / 100).toFixed(2)} Kč</td>
                    <td className="is-vcentered is-justify-content-center" style={{ justifyContent: 'center' }}>
                        <div className="select is-primary" style={{ margin: 'auto 0'}}>
                        <select
                            value={product.quantity}
                            onChange={(e) => handleQuantityChange(product.id, e.target.value)}
                        >
                            {[...Array(10).keys()].map(number => (
                            <option key={number + 1} value={number + 1}>
                                {number + 1}
                            </option>
                            ))}
                        </select>
                        </div>
                    </td>
                    <td className="has-text-right is-vcentered no-wrap">
                      {((product.unit_price / 100) * product.quantity).toFixed(2)} Kč
                    </td>
                  </tr>
              ))}
          </tbody>

          <tfoot>
            <tr>
              <td colSpan="3" className="has-text-right"><strong>Celkem:</strong></td>
              <td className="has-text-right" style={{ whiteSpace: 'nowrap' }}>
                <strong>{calculateTotal().toFixed(2)} Kč</strong>
              </td>
            </tr>
          </tfoot>
        </table>
    </div>
    </>
  );
};


const OrderPage = () => {
  const { id: cid } = useParams(); // Extract the campaign ID from the URL
  const [campaignData, setCampaignData] = useState(null);
  const [products, setProducts] = useState([]);
  const [email, setEmail] = useState('');
  const [isEmailValid, setIsEmailValid] = useState(false);
  // const [termsAccepted, setTermsAccepted] = useState(true);
  // const [gdprAccepted, setGdprAccepted] = useState(true);
  const [orderData, setOrderData] = useState(null); 
  const [showInvalidEmailMessage, setShowInvalidEmailMessage] = useState(false);
  // const [isOverlayGDPRVisible, setIsOverlayGDPRVisible] = useState(false);
  const [isOverlayErrorEmail, setIsOverlayErrorEmail] = useState(false);


  const navigate = useNavigate();

  // useCollectUserInfo();

  useFetchCampaignData(cid, setCampaignData);
  const fetchOrder = useFetchOrder();

  useEffect(() => {
    if (campaignData) {
      setProducts(campaignData.products.map(product => ({ ...product, quantity: 1 })));
    }
  }, [campaignData]);

  const handleOrder = () => {
    if (!isEmailValid) {
      setShowInvalidEmailMessage(true);
      setIsOverlayErrorEmail(true);
      return;
    }

    const orderData = {
      campaign_id: cid,
      ordered_item_count: products.reduce((total, product) => total + product.quantity, 0),
      email_address: email,
    };

    fetchOrder(orderData, (data) => {
      setOrderData(data);
      navigate('/payment-qr-page', { state: { orderData: data } }); 
    });
  };

  return (
    <>
      <Navbar />
      <div className='content mt-6'>
      <div className="columns is-centered">
        <div className='column is-half-desktop is-three-quarters-tablet is-full-mobile'>
        <div className='box custom-box mt-5 p-3 p-desktop-6'>
          <h3 className="title has-text-primary has-text-centered m-5">Objednávka vstupenek</h3>

        {campaignData ? (
            <div className="container">
              <div className='block'>
                <h3 className="title is-5 has-text-centered">{campaignData.name}</h3>
              </div>
              <div className='block'>
              <EmailInput
                    label="Email"
                    placeholder="Zadejte Vaši emailovou adresu"
                    invalidMessage="Neplatná emailová adresa"
                    onEmailChange={(email, isValid) => {
                      setEmail(email);
                      setIsEmailValid(isValid);
                      if (isValid) {
                        setShowInvalidEmailMessage(false);
                      }
                    }}
                    showInvalidMessage={showInvalidEmailMessage}
                  />
                <p className="subtitle has-text-grey is-6 mt-3 ml-2 mb-6">Na tento email Vám po uhrazení zašleme vstupenky.</p>
              </div>
              <div className='block'>
                <ProductList
                products={products}
                onProductsChange={setProducts}
                />
              </div>


                <div className='block'>
                  <div className='columns is-centered'>
                    <div className='column has-text-centered mt-5 mb-6'>
                  <button
                      className="button is-primary is-medium"
                      onClick={handleOrder}
                      >
                      Objednat
                  </button>
                  </div>
                  </div>


                </div>

            </div>
        ) : (
            <div>Loading...</div>
          )}
    </div>
    </div>
    </div>
    </div>
    {isOverlayErrorEmail && (
        <Overlay
          content={<OverlayContentErrorMessage errorMessage={"Zadejte platný email."} />}
          onClose={() => setIsOverlayErrorEmail(false)}
          hideCloseButton={false}
        />
      )}  
      {/* {isOverlayGDPRVisible && (
        <Overlay
          content={<GDPRContent />}
          onClose={() => setIsOverlayGDPRVisible(false)}
          hideCloseButton={false}
        />
      )} */}
    </>
  );
};

export default OrderPage;
