
const useFetchOrder = () => {
    const fetchOrder = async (orderData, setOrderData) => {
      try {
        const response = await fetch(`${process.env.REACT_APP_BASE_URL}/${process.env.REACT_APP_API_VERSION}/orders/`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(orderData),
        });
  
        if (!response.ok) {
          const errorData = await response.json();
          console.error('Error fetching order data:', errorData);
          return;
        }
  
        const data = await response.json();
        console.log('Order data:', data);
        setOrderData(data); // Set the entire order data
      } catch (error) {
        console.error('Error fetching order data:', error);
      }
    };
  
    return fetchOrder;
  };
  
  export default useFetchOrder;
  