import React from 'react';
import { LogoIcon } from './Logo';

const Separator = ({ color = 'primary', iconSize = '1em', strokeWidth = 1 }) => {
  const colorMap = {
    primary: 'var(--bulma-primary)',
    info: 'var(--bulma-info)',
    success: 'var(--bulma-success)',
    warning: 'var(--bulma-warning)',
    danger: 'var(--bulma-danger)',
  };

  const resolvedLineColor = colorMap[color] || color;

  const separatorContainerStyle = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    margin: '20px 0',
  };

  const lineStyle = {
    flexGrow: 1,
    height: '2px',
    backgroundColor: resolvedLineColor,
  };

  const iconBoxStyle = {
    backgroundColor: 'transparent',
    padding: '0 1em',
    display: 'flex',
    alignItems: 'center',
  };

  const iconStyle = {
    display: 'inline',
    color: resolvedLineColor,
  };

  return (
    <div style={separatorContainerStyle}>
      <div style={lineStyle}></div>
      <div style={iconBoxStyle}>
        <LogoIcon style={iconStyle} size={iconSize} strokeWidth={strokeWidth} />
        <LogoIcon style={iconStyle} size={iconSize} strokeWidth={strokeWidth} />
        <LogoIcon style={iconStyle} size={iconSize} strokeWidth={strokeWidth} />
      </div>
      <div style={lineStyle}></div>
    </div>
  );
};

export default Separator;
