import React, { useState } from 'react';
import Recaptcha from './Recaptcha';
import EmailInput from './EmailInput';

const ContactForm = () => {
    const [recaptchaValue, setRecaptchaValue] = useState(null);
    const [email, setEmail] = useState('');
    const [isEmailValid, setIsEmailValid] = useState(false);
    const [notification, setNotification] = useState({ message: '', type: '' }); // New state for notifications

    const handleRecaptchaChange = (value) => {
        setRecaptchaValue(value);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!recaptchaValue) {
            setNotification({ message: 'Prosím ověřte se pomocí CAPTCHA', type: 'is-danger' });
            return;
        }

        if (!isEmailValid) {
            setNotification({ message: 'Prosím zadejte platný email.', type: 'is-danger' });
            return;
        }

        const formData = {
            email,
            message: e.target.message.value,
            recaptcha_token: recaptchaValue,
        };

        try {
            const response = await fetch(`${process.env.REACT_APP_BASE_URL}/${process.env.REACT_APP_API_VERSION}/contact`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(formData),
            });

            if (response.ok) {
                const result = await response.json(); // Parse the JSON response from the backend
                setNotification({ message: result.message, type: 'is-success' }); // Use the message from the backend
                e.target.reset();
                setRecaptchaValue(null);
                setEmail('');
                setIsEmailValid(false);
            } else {
                setNotification({ message: 'Nezdařilo se... asi se budeme muset spojit jinak :-)', type: 'is-danger' });
            }
        } catch (error) {
            console.error("Form submission error: ", error);
            setNotification({ message: 'Nezdařilo se... asi se budeme muset spojit jinak :-)', type: 'is-danger' });
        }
    };

    const clearNotification = () => {
        setNotification({ message: '', type: '' });
    };

    return (
        <div>
            {/* Notification */}
            {notification.message && (
                <div className={`notification ${notification.type}`} style={{ position: 'relative' }}>
                    <button className="delete" onClick={clearNotification}></button>
                    {notification.message}
                </div>
            )}

            <form onSubmit={handleSubmit}>
                <EmailInput
                    label="Email"
                    placeholder="Zadejte váš email"
                    invalidMessage="Neplatná emailová adresa"
                    onEmailChange={(email, isValid) => {
                        setEmail(email);
                        setIsEmailValid(isValid);
                    }}
                />

                <div className="field">
                    <label className="label">Zpráva</label>
                    <div className="control">
                        <textarea
                            className="textarea is-primary"
                            name="message"
                            placeholder="Zadejte vaši zprávu"
                            required
                        ></textarea>
                    </div>
                </div>

                <div className="field mb-6" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                    <label className="label">Captcha</label>
                    <div className="control">
                        <Recaptcha onChange={handleRecaptchaChange} />
                    </div>
                </div>

                <div className="field is-grouped is-grouped-centered">
                    <div className="control">
                        <button className="button is-primary" type="submit" disabled={!isEmailValid}>
                            Odeslat
                        </button>
                    </div>
                </div>
            </form>
        </div>
    );
};

export default ContactForm;
