import React from 'react';

export const LogoIcon = ({ size = '1em', strokeWidth = 1 }) => {
  return (
    <svg 
      version="1.1" 
      viewBox="0 0 256 256" 
      style={{ 
        width: size, 
        height: size, 
        verticalAlign: 'middle' 
      }}
      fill="currentColor" 
      stroke="currentColor"
      strokeWidth={strokeWidth}
    >
      <g>
        <g>
          <g>
            <path d="M217.9,10.3C119.6,13.6,54.5,37.9,25.4,82c-18.6,28.2-20.5,65.4-4.7,92.1c1.2,2.1,3.7,5.7,5.4,7.9l3.3,4.1l-5.5,11.3c-8.2,16.7-12.4,28.8-13.7,39.1c-0.6,5.3,0.1,7.4,2.8,8.8c2.1,1,2.5,1,4.5,0c2-1,3.3-3.3,3.3-6.3c0-2.9,1.6-9.6,3.5-15.5c3.6-10.7,12.6-30,13.7-29.4c0.3,0.2,2.3,1.6,4.4,3.1c7.7,5.5,21.3,11.3,32.2,13.7c12.5,2.7,29.8,3.5,40.5,1.8c17.9-2.8,31.6-9.9,43.6-22.3c10.8-11.3,18.2-24.6,24.8-44.7c3-9.1,4.3-13.8,9.3-35.2c8-34.1,22.6-61.1,46.4-86.1c6.9-7.1,7.5-8.4,6.1-11.4C243.7,9.6,243,9.5,217.9,10.3z M226.5,22.3c-0.7,0.8-3.2,3.8-5.5,6.7c-11.3,13.5-20.8,29.1-27.7,45.4c-5.1,12-7.7,20.6-12.2,39.5c-5.6,23.4-8.8,33.6-14.9,46.3c-11.3,23.5-26.5,36.5-48.4,41.3c-6.7,1.5-21.5,1.9-30.3,0.8c-11.2-1.4-21.5-4.4-29.8-8.6c-3.1-1.6-10.9-6.6-13.5-8.7L43,184l3.8-6.3c11.4-18.7,25.8-37.1,41.9-53.5c20.4-20.8,39.5-35.3,62-47.1c8.2-4.3,9.4-5.4,9.4-8.5c0-2.3-2.8-5-5.1-5c-3.2,0-21.8,10.5-34.4,19.4c-30.3,21.4-60.6,53.7-81.5,87.1c-1.9,3.1-3.7,5.5-4,5.5c-1-0.3-5.4-7.2-7.8-12.2c-2.8-5.8-4.7-12.1-5.8-19.1c-1.1-7-0.7-20.2,0.9-27.4c10.2-47.9,58.5-79.6,138.5-91c19.8-2.9,41.6-4.6,61.3-5l5.5-0.1L226.5,22.3z"/>
          </g>
        </g>
      </g>
    </svg>
  );
};


// <div>Icons made from <a href="https://www.onlinewebfonts.com/icon">svg icons</a>is licensed by CC BY 4.0</div>

// const Logo = ({ size = '2em', weight = 'bold', iconSizeRatio = 4, strokeWidthRatio = 0.9 }) => {
//   // Calculate icon size and stroke width based on the font size
//   const fontSizeInPx = parseFloat(size) * 16; // Assuming size is in 'em', converting to pixels
//   const iconSize = `${fontSizeInPx * iconSizeRatio}px`;
//   const strokeWidth = fontSizeInPx * strokeWidthRatio; // Adjust stroke width relative to font size
//   return (
//     <div className="logo" style={{ fontSize: iconSize, fontWeight: weight, display: 'inline-flex', alignItems: 'center' }}>
//       <span style={{ fontSize: iconSize, fontWeight: weight }}>
//         kuplu
//         <svg 
//           version="1.1" 
//           viewBox="0 0 256 256" 
//           style={{ 
//             width: iconSize, 
//             height: iconSize, 
//             verticalAlign: 'middle', 
//             margin: '0 0.01em 0 0.1em' 
//           }}
//           fill="currentColor" 
//           stroke="currentColor"
//           strokeWidth={strokeWidth} // Dynamically set stroke-width
//         >
//           <g>
//             <g>
//               <g>
//                 <path d="M217.9,10.3C119.6,13.6,54.5,37.9,25.4,82c-18.6,28.2-20.5,65.4-4.7,92.1c1.2,2.1,3.7,5.7,5.4,7.9l3.3,4.1l-5.5,11.3c-8.2,16.7-12.4,28.8-13.7,39.1c-0.6,5.3,0.1,7.4,2.8,8.8c2.1,1,2.5,1,4.5,0c2-1,3.3-3.3,3.3-6.3c0-2.9,1.6-9.6,3.5-15.5c3.6-10.7,12.6-30,13.7-29.4c0.3,0.2,2.3,1.6,4.4,3.1c7.7,5.5,21.3,11.3,32.2,13.7c12.5,2.7,29.8,3.5,40.5,1.8c17.9-2.8,31.6-9.9,43.6-22.3c10.8-11.3,18.2-24.6,24.8-44.7c3-9.1,4.3-13.8,9.3-35.2c8-34.1,22.6-61.1,46.4-86.1c6.9-7.1,7.5-8.4,6.1-11.4C243.7,9.6,243,9.5,217.9,10.3z M226.5,22.3c-0.7,0.8-3.2,3.8-5.5,6.7c-11.3,13.5-20.8,29.1-27.7,45.4c-5.1,12-7.7,20.6-12.2,39.5c-5.6,23.4-8.8,33.6-14.9,46.3c-11.3,23.5-26.5,36.5-48.4,41.3c-6.7,1.5-21.5,1.9-30.3,0.8c-11.2-1.4-21.5-4.4-29.8-8.6c-3.1-1.6-10.9-6.6-13.5-8.7L43,184l3.8-6.3c11.4-18.7,25.8-37.1,41.9-53.5c20.4-20.8,39.5-35.3,62-47.1c8.2-4.3,9.4-5.4,9.4-8.5c0-2.3-2.8-5-5.1-5c-3.2,0-21.8,10.5-34.4,19.4c-30.3,21.4-60.6,53.7-81.5,87.1c-1.9,3.1-3.7,5.5-4,5.5c-1-0.3-5.4-7.2-7.8-12.2c-2.8-5.8-4.7-12.1-5.8-19.1c-1.1-7-0.7-20.2,0.9-27.4c10.2-47.9,58.5-79.6,138.5-91c19.8-2.9,41.6-4.6,61.3-5l5.5-0.1L226.5,22.3z"/>
//               </g>
//             </g>
//           </g>
//         </svg>
//         eny.cz
//       </span>
//     </div>
//   );
// };

// export default Logo;


export const Logo = ({ size = '2em', weight = 'bold', iconSizeRatio = 4, strokeWidthRatio = 0.9 }) => {
  // Calculate icon size and stroke width based on the font size
  const fontSizeInPx = parseFloat(size) * 16; // Assuming size is in 'em', converting to pixels
  const iconSize = `${fontSizeInPx * iconSizeRatio}px`;
  const strokeWidth = fontSizeInPx * strokeWidthRatio; // Adjust stroke width relative to font size

  return (
    <div className="logo" style={{ fontSize: size, fontWeight: weight, display: 'inline-flex', alignItems: 'center' }}>
      <span style={{ fontSize: size, fontWeight: weight }}>
        kuplu
        <LogoIcon 
          size={iconSize} 
          strokeWidth={strokeWidth} 
          style={{ margin: '0 0.01em 0 0.01em', verticalAlign: 'middle' }}
        />
        eny.cz
      </span>
    </div>
  );
};

export default Logo;



export const ResponsiveLogo = () => {
  const isMobile = window.innerWidth < 768;

  return (
    <Logo 
      size={isMobile ? '1.5em' : '3em'} 
      weight={isMobile ? 'normal' : 'bold'} 
      iconSizeRatio={isMobile ? 3 : 5} 
      strokeWidthRatio={isMobile ? 0.7 : 1} 
    />
  );
};

export const CenteredLogo = () => {
  // Inline styles
  const centeredLogoStripeStyle = {
      backgroundColor: 'var(--bulma-primary)',
      padding: '1em 0',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: '100%',
  };

  const logoContainerStyle = {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
  };

  return (
      <div style={centeredLogoStripeStyle}>
          <div style={logoContainerStyle}>
              <Logo size="1em" weight="bold" iconSizeRatio={1} strokeWidthRatio={0.7} />
          </div>
      </div>
  );
};

