import React, { useState } from 'react';
import { Link as ScrollLink } from 'react-scroll';
import { Link } from 'react-router-dom'; 
import { Logo } from './Logo';
import './Navbar.css';
import { Overlay } from './Overlay';
import { GDPRContent } from './OverlayContents';

const Navbar = () => {
    const [showGDPROverlay, setShowGDPROverlay] = useState(false);

    const toggleBurgerMenu = () => {
        const burger = document.querySelector('.navbar-burger');
        const menu = document.querySelector('.navbar-menu');
        burger.classList.toggle('is-active');
        menu.classList.toggle('is-active');
    };

    const handleGDPRClick = () => {
        setShowGDPROverlay(true);
    };

    const closeGDPRClick = () => {
        setShowGDPROverlay(false);
    };

    return (
        <>
            {/* Navbar */}
            <nav className="navbar is-fixed-top is-light" role="navigation" aria-label="main navigation">
                <div className="navbar-brand">
                    <Link className="navbar-item" to="/">
                        <Logo size="1em" weight="bold" iconSizeRatio={1} strokeWidthRatio={0.9} />
                    </Link>
                    <button
                        className="navbar-burger mr-4 is-primary"
                        aria-label="menu"
                        aria-expanded="false"
                        data-target="navbarBasic"
                        onClick={toggleBurgerMenu}
                    >
                        <span aria-hidden="true"></span>
                        <span aria-hidden="true"></span>
                        <span aria-hidden="true"></span>
                    </button>
                </div>
                <div id="navbarBasic" className="navbar-menu">
                    <div className="navbar-start navbar-center">
                        <ScrollLink className="navbar-item" to="events" smooth={true} duration={500} offset={-50}>
                            Akce
                        </ScrollLink>
                        <ScrollLink className="navbar-item" to="section-process" smooth={true} duration={500} offset={-50}>
                            Pro pořadatele
                        </ScrollLink>
                        <ScrollLink className="navbar-item" to="contact" smooth={true} duration={500} offset={-50}>
                            Kontakt
                        </ScrollLink>
                        <button className="navbar-item" onClick={handleGDPRClick}>
                            GDPR
                        </button>
                    </div>
                </div>
            </nav>

            {/* GDPR Overlay */}
            {showGDPROverlay && (
                <Overlay content={<GDPRContent onButtonClick={closeGDPRClick} />} onClose={closeGDPRClick} />
            )}
        </>
    );
};

export default Navbar;
