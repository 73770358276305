import { useState } from 'react';

const useConfirmPayment = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const confirmPayment = async (oid) => {
    setLoading(true);
    setError(null);

    try {
      const response = await fetch(`${process.env.REACT_APP_BASE_URL}/${process.env.REACT_APP_API_VERSION}/payment_confirm/${oid}`, {
        method: 'GET',
      });

      if (!response.ok) {
        const errorData = await response.json();
        console.error('Error confirming payment:', errorData);
        setError(errorData);
        return;
      }

      const data = await response.json();
      console.log('Payment confirmation response:', data);
      return data;
    } catch (error) {
      console.error('Error confirming payment:', error);
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  return { confirmPayment, loading, error };
};

export default useConfirmPayment;
